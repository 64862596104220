import React from 'react'
import style from './index.style.scss'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { observer } from 'mobx-react-lite'
import { service } from '@/api'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import { TableStore } from '@/pages/analytics/store/table/store'
import { runInAction } from 'mobx'
import { store } from '@/pages/analytics/store'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Icon } from '@/pages/analytics/components/ui'
import { debounce, tojs } from '@/shared'
import { sleep } from '@/shared'
import { ApiAnalyticsTypes } from '@/api'

interface IReferenceRowSelectProps {
    column: TableStore['columns'][0]
    row: TableStore['rows'][0]
    mode?: 'create' | 'edit' | 'view'
    className?: string
    style?: React.CSSProperties
}

export const ReferenceRowSelect: React.FC<IReferenceRowSelectProps> = observer(
    ({ column, mode, row, className, ...rest }) => {
        const isDependency =
            column?.dependencies && column.dependencies.find((dependency) => dependency.type === 'depends_on')

        const [isСhanged, setIsСhanged] = React.useState(false)
        const [loading, setLoading] = React.useState(false)
        const [empty, setEmpty] = React.useState(false)

        const columnId = mode === 'view' ? store.table.scheme.passport : column.id
        const items = store.table.selectData[row.id.value]?.[columnId]

        /**
         * Если есть хотя бы 1 зависимость для текущего select,
         * проверяем ее на наличие в data, если отсутствует то блокируем select
         */
        const isDisabled = React.useMemo(() => {
            if (isDependency) {
                return isDependency.values.some((tableId) => {
                    const data = store.table.editDataRows[row.id.value]?.[tableId]

                    return mode === 'edit' ? false : data === null || data === undefined
                })
            }

            return false
        }, [store.table.editDataRows[row.id.value], column, isDependency])

        const handleGetData = (id: string, search?: string) => async () => {
            let filters = {}

            console.log(123, id)

            if (loading) return

            /** Если есть зависимые данные, то добавляем их к запросу */
            if (isDependency) {
                const tableRow = store.table.rows.find((tableRow) => tableRow.id === row?.id)

                isDependency.values.forEach((tableId) => {
                    filters[`filters[${tableId}]`] =
                        store.table.editDataRows[row.id.value]?.[tableId]?.value || tableRow[tableId].id
                })
            }

            let findedScheme: ApiAnalyticsTypes['getScheme']['res']['payload'][0]['items'][0] = null

            store.table.schemes.forEach((scheme) => {
                scheme.items.forEach((item) => {
                    // if (item.id === id || item.passport === id) {
                    //     findedScheme = item
                    // } else if (item.items) {
                    //     item.items.forEach((item) => {
                    //         if (item.id === id || item.passport === id) {
                    //             findedScheme = item
                    //         }
                    //     })
                    // }

                    const isPassportName = mode === 'view' && column.id === 'name' && !!store.table.scheme.passport

                    if (!findedScheme) {
                        if (isPassportName && item.id === store.table.scheme.id) {
                            findedScheme = item
                        } else if (item.id === id) {
                            findedScheme = item
                        } else if (item.items) {
                            item.items.forEach((item) => {
                                if (!findedScheme) {
                                    if (isPassportName && item.id === store.table.scheme.id) {
                                        findedScheme = item
                                    } else if (item.id === id) {
                                        findedScheme = item
                                    }
                                }
                            })
                        }
                    }
                })
            })

            if (!findedScheme) return

            setLoading(true)

            await sleep(600)

            const requestData: ApiAnalyticsTypes['getReference']['req']['data'] = {
                'page[number]': '1',
                'page[size]': '25',
                'filters[is_archival]': false,
                ...filters,
                search: search,
                ...store.table.selectRequestData,
            }

            const response = await service.analytics.reference_get({
                url: findedScheme.api,
                data: requestData,
            })

            if (!response.data.length) {
                setEmpty(true)
            } else {
                setEmpty(false)
                runInAction(() => {
                    store.table.selectData = {
                        ...store.table.selectData,
                        [row.id.value]: {
                            ...store.table.selectData[row.id.value],
                            [id]: response.data,
                        },
                    }
                })
            }

            setLoading(false)
        }

        const handleSearch = React.useCallback(
            () =>
                debounce((e) => {
                    const value = e.target.value

                    setIsСhanged(true)

                    document
                        .querySelector(`.${style.paper} .MuiMenu-list`)
                        .scrollIntoView({ block: 'start', inline: 'nearest' })

                    handleGetData(columnId, value)()
                }, 200),
            []
        )

        React.useEffect(() => {
            /**
             * Для редактирования, добавляем в data дефолтное значение,
             * т.к. в списке данных запрашиваемых во время первого запроса,
             * дефолтного значения может не быть
             */
            if (mode === 'edit') {
                runInAction(() => {
                    store.table.editDataRows = {
                        ...store.table.editDataRows,
                        [row.id.value]: {
                            ...store.table.editDataRows[row.id.value],
                            [columnId]: {
                                type: 'select',
                                value: row[columnId]?.id,
                            },
                        },
                    }
                })
            }
        }, [])

        return (
            <FormControl className={clsx(style.root, className)} {...rest} size='small'>
                {mode === 'create' || mode === 'view' ? <InputLabel>{column.label}</InputLabel> : null}
                {
                    <Select
                        value={store.table.editDataRows[row.id.value]?.[column.id]?.value || ''}
                        label={column.label}
                        onChange={(e) => {
                            setIsСhanged(true)
                            store.table.handleChangeField(row.id.value, column, 'select')(e)
                        }}
                        MenuProps={{
                            classes: {
                                paper: clsx(style.paper, { [style.overflow]: loading }),
                            },
                            TransitionProps: {
                                onEnter: handleGetData(columnId),
                            },
                        }}
                        disabled={isDisabled}
                        sx={
                            mode === 'edit'
                                ? {
                                      '& legend': { display: 'none' },
                                      '& fieldset': { top: 0 },
                                      '& .MuiInputBase-input': {
                                          fontSize: '14px',
                                      },
                                  }
                                : {}
                        }
                    >
                        <TextField
                            placeholder='Найти'
                            size='small'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='start'>
                                        <Icon icon='search' sx={{ fontSize: 20 }} />
                                    </InputAdornment>
                                ),
                            }}
                            className={style.input}
                            sx={{
                                padding: 1,
                                position: 'sticky',
                                top: 0,
                                zIndex: 1,
                                background: '#fff',
                                marginTop: '-8px',
                                borderBottom: '1px solid var(--a-color-light-gray)',
                                '& .MuiInputBase-adornedEnd': {
                                    paddingRight: '0px',
                                },
                            }}
                            onChange={handleSearch()}
                            onKeyDown={(event: any) => {
                                event.stopPropagation()
                            }}
                        />
                        {/* <Scroll height={182}> */}
                        {loading && (
                            <div className={style.loader}>
                                <CircularProgress />
                            </div>
                        )}

                        {empty ? <div className={style.notfound}>Значений не найдено</div> : null}

                        {/*
                            Для редактирования добавляем дефолтный item, т.к.
                            в списке данных запрашиваемых во время первого запроса,
                            дефолтного значения может не быть
                        */}
                        {mode === 'edit' && row[column.id]?.value ? (
                            <MenuItem
                                className={style.item}
                                value={row[column.id]?.id}
                                style={rest?.style || {}}
                                sx={{
                                    marginBottom: '8px',
                                    borderBottom: '1px  solid #efefef',
                                }}
                            >
                                {row[column.id]?.value}
                            </MenuItem>
                        ) : null}

                        {/* Для редактирования исключаем из списка дефолтное значение */}
                        {items
                            ?.filter((item) => item.id.value !== row[column.id]?.id)
                            .map((item, i) => {
                                return (
                                    <MenuItem
                                        className={style.item}
                                        key={i}
                                        value={item.id.value}
                                        style={rest?.style || {}}
                                    >
                                        {item.name?.value}
                                    </MenuItem>
                                )
                            })}

                        {/* {!empty && (mode === 'create' || isСhanged)
                            ? store.table.selectData[row.id.value]?.[column.id]?.map((item, i) => {
                                  return (
                                      <MenuItem
                                          className={style.item}
                                          key={i}
                                          value={item.id.value}
                                          style={rest?.style || {}}
                                      >
                                          {item.name?.value}
                                      </MenuItem>
                                  )
                              })
                            : null} */}
                        {/* </Scroll> */}
                    </Select>
                }
            </FormControl>
        )
    }
)

ReferenceRowSelect.defaultProps = {
    mode: 'create',
}
